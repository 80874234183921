import React from "react"
import { Link } from "gatsby"
import { BsArrowRight } from "../../../node_modules/react-icons/bs"

interface ReadMoreArrowBtnProps {
  uri: string
  label: string
}

const readMoreArrowBtn = ({ uri, label }: ReadMoreArrowBtnProps) => {
  return (
    <>
      <Link className="read-more-arrow-btn" to={uri}>
        {label}
        <BsArrowRight
          className="read-more-arrow"
          role="button"
          tabIndex={0}
          size={24}
          color="#b75a5a"
        ></BsArrowRight>
      </Link>
    </>
  )
}

export default readMoreArrowBtn
