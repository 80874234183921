import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ReadMoreArrowBtn from "../components/buttons/readMoreArrowBtn"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Villa" />
    <div id="error-page">
      <div className="content-wrapper">
        <StaticImage
          src="../assets/images/404_img.svg"
          alt="Ljósmynd af Rósu Ólöf."
          as="div"
          loading="eager"
          className="error-img"
          placeholder="none"
          layout="constrained"
          width={500}
        />
        <div className="text-container">
          <h2 className="heading-primary">404: Villa</h2>
          <p className="p-main-large">
            Oops, þessi síða er ekki til staðar. En endilega, gangtu í bæinn ..
          </p>
          <ReadMoreArrowBtn uri={"/"} label={"Forsíða"} />
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
